import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { SF_TYPE } from "../../constant/status_constant";

const Asidebar = ({ isDisplay, setDisplay }) => {
  // State to track which dropdown is open
  const [openDropdowns, setOpenDropdowns] = useState({});

  // Toggle function for dropdowns
  const toggleDropdown = (name) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };
  return (
    <div>
      {/* Main Sidebar Container */}
      <aside
        onMouseEnter={() => setDisplay(false)}
        className="main-sidebar main-sidebar-custom sidebar-light-primary"
        style={{ borderRight: "1px solid rgb(222, 226, 230)" }}
      >
        {/* Brand Logo */}
        <NavLink to={"#"} className="brand-link">
          <img
            src="../../dist/img/cloud.png"
            alt="Logo"
            className={`brand-image ${!isDisplay ? "brandtextnot" : ""} `}
            style={{ height: "190px", marginTop: "6px" }}
          />
          <span className="brand-text font-weight-light">
            {/* {process.env.REACT_APP_TITLE} */}
            <img
              src="../../dist/img/logo.png"
              alt="Logo"
              className=""
              style={{ width: "190px" }}
            />
          </span>
        </NavLink>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-child-indent"
              // data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              <li className="nav-item">
                <NavLink to={"/dashboard"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-home"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"/search"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-search"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Search</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={"/pendingbooking"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-book"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Pending Booking</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/getcompletedlist"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-list-alt"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Completed Booking</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/getcancelledlist"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-times"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Cancelled Booking</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to={"/spareparts"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-gear"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Spare Parts</p>
                </NavLink>
              </li>

              <li
                className={`nav-item has-treeview ${
                  openDropdowns["reportsummary"] ? "menu-open" : ""
                }`}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  onClick={() => toggleDropdown("reportsummary")}
                >
                  <i
                    className="nav-icon fa-solid fa-file-circle-plus"
                    style={{ fontSize: "15px" }}
                  ></i>

                  <p>
                    Reports
                    <i
                      className={`right fas fa-angle-${
                        openDropdowns["reportsummary"] ? "down" : "left"
                      }`}
                      style={{ marginLeft: "auto" }}
                    />
                  </p>
                </Link>
                <ul
                  className={`nav nav-treeview ${
                    openDropdowns["reportsummary"] ? "d-block" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink
                      to={"/bookingsummary"}
                      className="nav-link"
                      exact="true"
                    >
                      <i
                        className="nav-icon fas fa-b"
                        style={{ fontSize: "15px" }}
                      ></i>
                      <p>Booking Summary</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/sparesummary"}
                      className="nav-link"
                      exact="true"
                    >
                      <i
                        className="nav-icon fas fa-b"
                        style={{ fontSize: "15px" }}
                      ></i>
                      <p>Spare Summary</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li
                className={`nav-item has-treeview ${
                  openDropdowns["inv "] ? "menu-open" : ""
                }`}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  onClick={() => toggleDropdown("inv")}
                >
                  <i
                    className="nav-icon fas fa-file-invoice"
                    style={{ fontSize: "15px" }}
                  />
                  <p>
                    Invoice
                    <i
                      className={`right fas fa-angle-${
                        openDropdowns["inv"] ? "down" : "left"
                      }`}
                      style={{ marginLeft: "auto" }}
                    />
                  </p>
                </Link>
                <ul
                  className={`nav nav-treeview ${
                    openDropdowns["inv"] ? "d-block" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink to={"/customeroowinvoice"} className="nav-link">
                      <i
                        className="far fa-c nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Customer Invoice</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={`/invoicesummary/${btoa(SF_TYPE)}`}
                      className="nav-link"
                      exact={true}
                    >
                      <i
                        className="far fa-s nav-icon"
                        style={{ fontSize: "15px" }}
                      />
                      <p>Invoice Summary</p>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        <br />
        {/* /.sidebar */}
        {/* <div className="sidebar-custom"></div> */}
        {/* /.sidebar-custom */}
      </aside>
    </div>
  );
};

export default Asidebar;

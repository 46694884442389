import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { PARTNER_TYPE } from "../../constant/status_constant";

const Asidebar = ({ isDisplay, setDisplay }) => {
  // let user = useSelector((state) => state.authReducer.currentUser);

  // State to track which dropdown is open
  const [openDropdowns, setOpenDropdowns] = useState({});

  // Toggle function for dropdowns
  const toggleDropdown = (name) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  return (
    <div>
      {/* Main Sidebar Container */}
      <aside
        onMouseEnter={() => setDisplay(false)}
        className="main-sidebar main-sidebar-custom sidebar-light-primary"
        style={{ borderRight: "1px solid rgb(222, 226, 230)" }}
      >
        {/* Brand Logo */}
        <NavLink to={"#"} className="brand-link">
          <img
            src="../../dist/img/cloud.png"
            alt="Logo"
            className={`brand-image ${!isDisplay ? "brandtextnot" : ""} `}
            style={{ height: "190px", marginTop: "6px" }}
          />
          <span className="brand-text font-weight-light">
            {/* {process.env.REACT_APP_TITLE} */}
            <img
              src="../../dist/img/logo.png"
              alt="Logo"
              className=""
              style={{ width: "190px" }}
            />
          </span>
        </NavLink>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column nav-child-indent"
              // data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              <li className="nav-item">
                <NavLink to={"/dashboard"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-home"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={"/search"} className="nav-link" exact="true">
                  <i
                    className="nav-icon fas fa-search"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Search</p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to={"/pendingbooking"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-book"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Pending Booking</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/getcompletedlist"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-list-alt"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Completed Booking</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={"/getcancelledlist"}
                  className="nav-link"
                  exact="true"
                >
                  <i
                    className="nav-icon fas fa-times"
                    style={{ fontSize: "15px" }}
                  ></i>
                  <p>Cancelled Booking</p>
                </NavLink>
              </li>
              <li
                className={`nav-item has-treeview ${
                  openDropdowns["reports"] ? "menu-open" : ""
                }`}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  onClick={() => toggleDropdown("reports")}
                >
                  <i
                    className="nav-icon fa-solid fa-file-circle-plus"
                    style={{ fontSize: "15px" }}
                  ></i>

                  <p>
                    Reports
                    <i
                      className={`right fas fa-angle-${
                        openDropdowns["reports"] ? "down" : "left"
                      }`}
                      style={{ marginLeft: "auto" }}
                    />
                  </p>
                </Link>
                <ul
                  className={`nav nav-treeview ${
                    openDropdowns["reports"] ? "d-block" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink
                      to={"/bookingsummary"}
                      className="nav-link"
                      exact="true"
                    >
                      <i
                        className="nav-icon fas fa-b"
                        style={{ fontSize: "15px" }}
                      ></i>
                      <p>Booking Summary</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/sparesummary"}
                      className="nav-link"
                      exact="true"
                    >
                      <i
                        className="nav-icon fas fa-b"
                        style={{ fontSize: "15px" }}
                      ></i>
                      <p>Spare Summary</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <>
                <li
                  className={`nav-item has-treeview ${
                    openDropdowns["invoices"] ? "menu-open" : ""
                  }`}
                >
                  <Link
                    to={"#"}
                    className="nav-link"
                    onClick={() => toggleDropdown("invoices")}
                  >
                    <i
                      className="nav-icon fas fa-file-invoice"
                      style={{ fontSize: "15px" }}
                    />
                    <p>
                      Invoice
                      <i
                        className={`right fas fa-angle-${
                          openDropdowns["invoices"] ? "down" : "left"
                        }`}
                        style={{ marginLeft: "auto" }}
                      />
                    </p>
                  </Link>
                  <ul
                    className={`nav nav-treeview ${
                      openDropdowns["invoices"] ? "d-block" : "d-none"
                    }`}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={`/invoicesummary/${btoa(PARTNER_TYPE)}`}
                        className="nav-link"
                        exact={true}
                      >
                        <i
                          className="far fa-p nav-icon"
                          style={{ fontSize: "15px" }}
                        />
                        <p>Invoice Summary</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li
                  className={`nav-item has-treeview ${
                    openDropdowns["wh"] ? "menu-open" : ""
                  }`}
                >
                  <Link
                    to={"#"}
                    className="nav-link"
                    onClick={() => toggleDropdown("wh")}
                  >
                    <i
                      className="nav-icon fas fa-store"
                      style={{ fontSize: "15px" }}
                    />
                    <p>
                      Warehouse
                      <i
                        className={`right fas fa-angle-${
                          openDropdowns["wh"] ? "down" : "left"
                        }`}
                        style={{ marginLeft: "auto" }}
                      />
                    </p>
                  </Link>
                  <ul
                    className={`nav nav-treeview ${
                      openDropdowns["wh"] ? "d-block" : "d-none"
                    }`}
                  >
                    <li className="nav-item">
                      <NavLink to={"/bomlist"} className="nav-link">
                        <i
                          className="far fa-b nav-icon"
                          style={{ fontSize: "15px" }}
                        />
                        <p>Bom List</p>
                      </NavLink>
                    </li>

                    <li
                      className={`nav-item has-treeview ${
                        openDropdowns["msls"] ? "menu-open" : ""
                      }`}
                    >
                      <Link
                        to={"#"}
                        className="nav-link"
                        onClick={() => toggleDropdown("msls")}
                      >
                        <i
                          className="nav-icon fas fa-m"
                          style={{ fontSize: "15px" }}
                        />
                        <p>
                          Msl
                          <i
                            className={`right fas fa-angle-${
                              openDropdowns["msls"] ? "down" : "left"
                            }`}
                            style={{ marginLeft: "auto" }}
                          />
                        </p>
                      </Link>
                      <ul
                        className={`nav nav-treeview ${
                          openDropdowns["msls"] ? "d-block" : "d-none"
                        }`}
                      >
                        <li className="nav-item">
                          <NavLink to={"/uploadinwardmsl"} className="nav-link">
                            <i
                              className="far fa-circle nav-icon"
                              style={{ fontSize: "15px" }}
                            />
                            <p>Msl Inward</p>
                          </NavLink>
                          <NavLink to={"/whintranistmsl"} className="nav-link">
                            <i
                              className="far fa-circle nav-icon"
                              style={{ fontSize: "15px" }}
                            />
                            <p>Msl In-Transit</p>
                          </NavLink>
                          <NavLink to={"/whmsldisputed"} className="nav-link">
                            <i
                              className="far fa-circle nav-icon"
                              style={{ fontSize: "15px" }}
                            />
                            <p>Msl Disputed List</p>
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <NavLink to={"/partlist"} className="nav-link">
                        <i
                          className="far fa-p nav-icon"
                          style={{ fontSize: "15px" }}
                        />
                        <p>Part List</p>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to={"/warehousestock"} className="nav-link">
                        <i
                          className="far fa-w nav-icon"
                          style={{ fontSize: "15px" }}
                        />
                        <p>Warehouse Stock</p>
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink to={"/spareparts"} className="nav-link">
                        <i
                          className="far fa-w nav-icon"
                          style={{ fontSize: "15px" }}
                        />
                        <p>Warehouse Task</p>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        <br />
        {/* /.sidebar */}
        {/* <div className="sidebar-custom"></div> */}
        {/* /.sidebar-custom */}
      </aside>
    </div>
  );
};

export default Asidebar;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import MyDataTable from "../../component/MyDataTable";
import httpRequest from "../../utils/Http";
import Select from "react-select";
import { getPartWarranty, getdropdownList } from "../../utils/HelperFunction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UploadFileToS3 } from "../../utils/S3File";
import PrintAddressesComponent from "./PrintAddressesComponent";

const DispatchParts = ({
  reloadList,
  setFormErrors,
  formErrors,
  setReload,
}) => {
  const [formData, setFormData] = useState({});
  const [serviceChecked, setServiceChecked] = useState([]);
  const [isSubmit, setSubmit] = useState(false);
  const [selectedScAndPartner, setSelectedScAndPartner] = useState(null);
  const [checkboxError, setCheckboxError] = useState("");
  const [rejectForm, setRejectForm] = useState(false);
  const [rejectData, setRejectData] = useState({});
  const [rejectErrors, setRejectErrors] = useState({});
  const [cancellist, setCancelList] = useState([]);
  const [isOpenForm, setOpenForm] = useState(false);
  const [courierNamelist, setCourierNameList] = useState([]);
  const [courierDate, setCourierDate] = useState();
  const [challanDate, setChallanDate] = useState();
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [visibalePrint, setVisiblePrint] = useState(false);

  // Handle checkbox change
  const handleChargesCheckbox = (event, row) => {
    const { id, sc_id } = row;

    if (event.target.checked) {
      if (!selectedScAndPartner || selectedScAndPartner.sc_id === sc_id) {
        setSelectedScAndPartner({ sc_id });
        setCheckboxError("");
        setFormData((prevData) => ({
          ...prevData,
          sc_id,
          data: [...(prevData.data || []), id],
        }));
        setServiceChecked((prevChecked) => [...prevChecked, id]);
      } else {
        setCheckboxError(
          "You can only select checkboxes with the same Service Center."
        );
      }
    } else {
      setServiceChecked((prevChecked) =>
        prevChecked.filter((checkedId) => checkedId !== id)
      );
      setFormData((prevData) => ({
        ...prevData,
        data: (prevData.data || []).filter((itemId) => itemId !== id),
      }));
      if (serviceChecked.length === 1) {
        setSelectedScAndPartner(null);
        setFormData((prevData) => ({ ...prevData, sc_id: null }));
      }
    }
  };

  // Handle checkbox change
  const handleAddressCheckbox = (event, row) => {
    const { id } = row;

    if (event.target.checked) {
      setSelectedAddresses((prevAddresses) => [...prevAddresses, row]);
    } else {
      setSelectedAddresses((prevAddresses) =>
        prevAddresses.filter((address) => address.id !== id)
      );
    }
  };

  useEffect(() => {
    (async () => {
      getdropdownList(setCourierNameList, `getcouriernamelist?is_active=1`);
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const cancel = await httpRequest(
        "getcancellationreasonlist?is_active=1&type=spareparts",
        {},
        "GET"
      );
      if (cancel.status === true && cancel.data) {
        setCancelList(cancel.data["rows"]);
      }
    })();
  }, []);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      selectedCheckbox: serviceChecked,
    }));
  }, [serviceChecked]);

  const columns = [
    {
      name: "SNo.",
      selector: (row) => '', // Placeholder, as we don't need to select data from the row itself
      cell: (row, index) => index + 1, // Use cell to render the incrementing number
    },
    {
      name: <div className="wrap-word">Spare Requested Id</div>,
      selector: (row) => row.requested_id,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Booking ID</div>,
      selector: (row) => row.booking_id,
      cell: (row) => (
        <NavLink to={`/getbookingdetails?bid=${btoa(row.bid)}`}>
          {row.booking_id}
        </NavLink>
      ),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Service Center</div>,
      selector: (row) => row.sc_name,
      wrap: true,
    },
    {
      name: "Part Name",
      selector: (row) => `${row.part_name} / ${row.part_number}` ,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Model Number</div>,
      selector: (row) => row.model_number,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.requested_quantity,
      wrap: true,
    },
    {
      name: "Warranty",
      selector: (row) => getPartWarranty(row.warranty_status),
      wrap: true,
    },
    {
      name: <div className="word-wrap">Age of Request</div>,
      selector: (row) => `${row.age_of_requested} days`,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Problem Description</div>,
      selector: (row) => row.problem_description,
      wrap: true,
    },
    {
      name: <div className="word-wrap">Print Address</div>,
      cell: (row) => (
        <input
          type="checkbox"
          className="form-control charges_checkbox text-capitalize"
          name="address_checkbox"
          id={"address_checkbox" + row.requested_id}
          value={row.requested_id}
          checked={selectedAddresses.some(
            (address) => address.id === row.requested_id
          )}
          onChange={(e) =>
            handleAddressCheckbox(e, {
              id: row.requested_id,
              sf_company_name: row.sf_company_name,
              poc_name: row.poc_name,
              sf_address: row.sf_address,
              sf_city: row.sf_city,
              sf_pincode: row.sf_pincode,
              sf_state: row.sf_state,
              poc_mobile_number: row.poc_mobile_number,
              booking_id: row.booking_id,
              model_number: row.model_number,
              part_name: row.part_name,
              part_number: row.part_number,
              sc_id: row.sc_id,
              sf_gst_number: row.sf_gst_number,
            })
          }
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: "Reject",
      cell: (row) => (
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            setRejectData({ id: row.id });
            setRejectForm(true);
          }}
        >
          Reject
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    {
      name: <div className="word-wrap">Dispatch Part</div>,
      cell: (row) => (
        <NavLink
          className="btn btn-sm btn-success"
          to={`/dispatchparts/${btoa(row.id)}`}
        >
          Dispatch
        </NavLink>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      exportXlsx: false,
    },
    // {
    //   name: "Bulk Dispatch",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       className="form-control charges_checkbox text-capitalize"
    //       name="charges_checkbox"
    //       id={"charges_checkbox" + row.requested_id}
    //       value={row.requested_id}
    //       checked={serviceChecked.includes(row.requested_id)}
    //       onChange={(e) => handleChargesCheckbox(e, row)}
    //     />
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   exportXlsx: false,
    // },
  ].filter(Boolean);

  const generateChallan = async (e) => {
    e.preventDefault();
    setSubmit(false);
    setFormErrors(await validateForm(formData));
    setSubmit(true);
  };

  const validateForm = async (value) => {
    const errors = {};

    if (serviceChecked.length < 1) {
      errors.checkbox = "Select at least one checkbox";
    }

    if (!value.challan_number) {
      errors.challan_number = "Challan Number is required";
    } else {
      const checkchallanExit = await httpRequest(
        `checkchallanexist`,
        { challan_number: value.challan_number },
        "POST"
      );

      if (checkchallanExit.status === true) {
        errors.challan_number = "Challan Number already uploaded.";
      }
    }

    if (!value.challan_date) {
      errors.challan_date = "Challan Date is required";
    }

    if (!value.courier_name) {
      errors.courier_name = "Courier Name is required";
    }

    if (!value.awb_number) {
      errors.awb_number = "AWb number is required";
    }

    if (!value.courier_price) {
      errors.courier_price = "Courier Price is required";
    }

    if (!value.courier_date) {
      errors.courier_date = "Courier Date is required";
    }
    if (!value.remarks) {
      errors.remarks = "Remarks is required";
    }

    if (!value.challan_file) {
      errors.challan_file = "Challan file is required";
    }

    return errors;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const postData = { ...formData };
        if (formData.courier_file) {
          const courier_file = await UploadFileToS3(
            "docFile",
            formData.courier_file
          );
          postData.courier_file = courier_file;
        }

        if (formData.challan_file) {
          const challan_file = await UploadFileToS3(
            "docFile",
            formData.challan_file
          );
          postData.challan_file = challan_file;
        }
        const data = await httpRequest(
          "updateforwardshippmentfrompartner",
          postData,
          "POST"
        );

        setSubmit(false);
        if (data.status === true && data.message) {
          toast.success(data.message);
          setOpenForm(false);
          setFormData({});
          setReload(true);
        } else {
          toast.error(data.message);
        }
      }
    })();
    //eslint-disable-next-line
  }, [isSubmit, formErrors]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (!rejectData.remarks) {
      setRejectErrors({ ...rejectErrors, remarks: "Remarks is Mandatory" });
      return;
    }

    if (!rejectData.cancellation_id) {
      setRejectErrors({
        ...rejectErrors,
        cancellation_id: "Cancellation Reason is Mandatory",
      });
      return;
    }

    (async () => {
      const data = await httpRequest("rejectspareparts", rejectData, "POST");

      if (data.status === true && data.message) {
        toast.success(data.message);
        setReload(true);
        setRejectData({});
        setRejectForm(false);
      } else {
        toast.error(data.message);
      }
    })();
  };

  const formReject = () => {
    return (
      <>
        <div className="form-overlay">
          <div className="form-container" style={{ maxWidth: "500px" }}>
            <div className="form-content">
              <form id="courierupdate" onSubmit={onSubmitHandler}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Reject Spare</h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={() => {
                            setRejectData({});
                            setRejectForm(false);
                          }}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Cancellation Reason *{" "}
                                <span style={{ color: "red" }}>
                                  {rejectErrors.cancellation_id}
                                </span>
                              </label>
                              <Select
                                name="cancellation_id"
                                value={
                                  cancellist.find(
                                    (option) =>
                                      option.value ===
                                      rejectData.cancellation_id
                                  ) || null
                                }
                                options={cancellist}
                                onChange={(val) => {
                                  setRejectData({
                                    ...rejectData,
                                    cancellation_id: val.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {rejectErrors.remarks}
                                </span>
                              </label>
                              <textarea
                                type="text"
                                name="remarks"
                                className="form-control"
                                value={rejectData.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => {
                                  setRejectData({
                                    ...rejectData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  const formAddUpdate = () => {
    return (
      <>
        <div className="form-overlay" style={{ zIndex: 10 }}>
          <div className="form-container">
            <div className="form-content">
              <form id="courierupdate" onSubmit={generateChallan}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card" style={{ marginBottom: "0px" }}>
                      <div className="card-header">
                        <h3 className="card-title">Update Shippment</h3>
                        <button
                          className="btn btn-sm btn-dark"
                          onClick={() => {
                            setOpenForm(false);
                          }}
                          style={{ float: "right" }}
                        >
                          X
                        </button>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Challan Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_number}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="challan_number"
                                value={formData.challan_number}
                                className="form-control"
                                placeholder="Enter Challan Number"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    challan_number: val.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Challan Date *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_date}
                                </span>
                              </label>
                              <DatePicker
                                className="form-control text-capitalize"
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={challanDate}
                                placeholderText="Select Challan Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    challan_date: date,
                                  });
                                  setChallanDate(date);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Challan file{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.challan_file}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="challan_file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    challan_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_name}
                                </span>
                              </label>
                              <Select
                                name="courier_name"
                                value={
                                  courierNamelist.find(
                                    (option) =>
                                      option.label === formData.courier_name
                                  ) || null
                                }
                                options={courierNamelist}
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    courier_name: val.label,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                AWB Number *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.awb_number}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="awb_number"
                                value={formData.awb_number}
                                className="form-control"
                                placeholder="Enter AWB Number"
                                onChange={(val) => {
                                  setFormData({
                                    ...formData,
                                    awb_number: val.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Price *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_price}
                                </span>
                              </label>
                              <input
                                type="number"
                                min={0}
                                value={formData.courier_price}
                                name="courier_name"
                                className="form-control"
                                placeholder="Enter Docket NUmber"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_price: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier Date *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_date}
                                </span>
                              </label>
                              <DatePicker
                                className="form-control text-capitalize"
                                minDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() - 7)
                                  )
                                }
                                dateFormat="dd/MM/yyyy"
                                selected={courierDate}
                                placeholderText="Select Courier Date"
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    courier_date: date,
                                  });
                                  setCourierDate(date);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Courier invoice file{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.courier_file}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="courier_file"
                                className="form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    courier_file: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Remarks *{" "}
                                <span style={{ color: "red" }}>
                                  {formErrors.remarks}
                                </span>
                              </label>
                              <input
                                type="text"
                                name="remarks"
                                className="form-control"
                                value={formData.remarks}
                                placeholder="Enter Remarks"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    remarks: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="card-footer text-center"
                        style={{
                          marginBottom: "60px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <button
                          type="submit"
                          id="submit"
                          className="btn btn-primary col-md-12"
                          style={{ marginLeft: "0px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MyDataTable
                reloadList={reloadList}
                columns={columns}
                getDataListURL={`getparttobedispatchfrompartner?partner=all`}
                setReload={setReload}
                exportName={"sparelist"}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        {rejectForm && formReject()}
        {isOpenForm && formAddUpdate()}
        {selectedAddresses.length > 0 && (
          <div className="col-md-12 ">
            <span style={{ color: "red" }}>{formErrors.checkbox}</span>
            <span style={{ color: "red" }}>{checkboxError}</span>
            <button
              className="btn btn-primary"
              disabled={selectedAddresses.length > 0 ? false : true}
              onClick={() => {
                setVisiblePrint(true);
              }}
            >
              Print All Address
            </button>

            {/* <button
                        className="btn btn-primary"
                        disabled={serviceChecked.length > 0 ? false : true}
                        onClick={() => {
                            setOpenForm(true);
                          }}
                      >
                        Bulk Dispatch
                      </button> */}
          </div>
        )}

        {visibalePrint && (
          <PrintAddressesComponent
            selectedAddresses={selectedAddresses}
            setSelectedAddresses={setSelectedAddresses}
            setVisiblePrint={setVisiblePrint}
          />
        )}
      </section>
    </div>
  );
};

export default DispatchParts;
